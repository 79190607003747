@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.resContainer {
  width: 1200px;
  max-width: 1200px;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .resContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .resContainer {
    width: 93%;
  }
}

/* Loader styels */
.loader {
  width: 80px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #0a0180;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}
